.sectionTitle {
  padding: 2.5rem;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  -webkit-user-select: none;
  user-select: none;
}

.sectionTitle div {
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sectionTitle h4 {
  display: flex;
  align-items: center;
}

.sectionTitle h4 span {
  margin-right: 0.5rem;
}

.sectionTitle.active {
  color: white;
  background: hsl(218, 75%, 45%);
  border-color: hsl(218, 75%, 45%);
}

/* .sectionTitle:not(.active).completed {
  border-left: 5px solid green !important;
} */
