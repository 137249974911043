.header {
  margin-bottom: 1.25em !important;
  font-size: 3rem !important;
  color: #fff !important;
}

.subHeader {
  /* font-size: 1.4rem !important; */
  color: #fff !important;
  opacity: 0.6 !important;
}
